import React, { FC } from 'react';
import { Web3Provider } from './Web3Provider';
import { Main } from './Main';
import { ChakraProvider } from '@chakra-ui/react';
import * as buffer from "buffer";
import { theme } from '../mainTheme';
import "@fontsource/courier-prime";
import { coinAddresses, CoinAddressInterface, cryptoLogos, explorerCLusters, SupportedAsset } from '../common';
window.Buffer = buffer.Buffer;
require('../css/App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

const NETWORK_TYPE = process.env.REACT_APP_NETWORK_TYPE || 'DEVNET';
export const addresses: CoinAddressInterface = coinAddresses[NETWORK_TYPE];
export const EXPLORER_CLUSTER: string = explorerCLusters[NETWORK_TYPE];

export const assetsSupported: SupportedAsset[] = [
    { value: 'USDC', label: 'USDC', img: cryptoLogos.USDC, mint: addresses.usdc, decimals: 6 },
    { value: 'SOL', label: 'SOL', img: cryptoLogos.SOL, mint: addresses.sol, decimals: 9 },
    { value: 'HNT', label: 'HNT', img: cryptoLogos.HNT, mint: addresses.hnt, decimals: 8 },
    { value: 'JTO', label: 'JTO', img: cryptoLogos.JTO, mint: addresses.jto, decimals: 9 },
    { value: 'BONK', label: 'BONK', img: cryptoLogos.BONK, mint: addresses.bonk, decimals: 5 },
    { value: 'RAY', label: 'RAY', img: cryptoLogos.RAY, mint: addresses.ray, decimals: 6 },
    { value: 'JUP', label: 'JUP', img: cryptoLogos.JUP, mint: addresses.jup, decimals: 6 },
    { value: 'PYTH', label: 'PYTH', img: cryptoLogos.PYTH, mint: addresses.pyth, decimals: 6 },
    { value: 'RENDER', label: 'RENDER', img: cryptoLogos.RENDER, mint: addresses.render, decimals: 8 },
    { value: 'AURY', label: 'AURY', img: cryptoLogos.AURY, mint: addresses.aury, decimals: 9 },
    { value: 'HONEY', label: 'HONEY', img: cryptoLogos.HONEY, mint: addresses.honey, decimals: 9 },
    { value: 'ORCA', label: 'ORCA', img: cryptoLogos.ORCA, mint: addresses.orca, decimals: 6 },
    { value: 'USDT', label: 'USDT', img: cryptoLogos.USDT, mint: addresses.usdt, decimals: 6 },
    { value: 'WIF', label: 'WIF', img: cryptoLogos.WIF, mint: addresses.wif, decimals: 6 },
    { value: 'WEN', label: 'WEN', img: cryptoLogos.WEN, mint: addresses.wen, decimals: 5 },
    { value: 'NOS', label: 'NOS', img: cryptoLogos.NOS, mint: addresses.nos, decimals: 6 },
    { value: 'SHDW', label: 'SHDW', img: cryptoLogos.SHDW, mint: addresses.shdw, decimals: 9 },
    { value: 'W', label: 'W', img: cryptoLogos.W, mint: addresses.w, decimals: 6 },
    { value: 'KMNO', label: 'KMNO', img: cryptoLogos.KMNO, mint: addresses.kmno, decimals: 6 },
    { value: 'BORG', label: 'BORG', img: cryptoLogos.BORG, mint: addresses.borg, decimals: 9 },
    { value: 'MOTHER', label: 'MOTHER', img: cryptoLogos.MOTHER, mint: addresses.mother, decimals: 6 },
    { value: 'WXM', label: 'WXM', img: cryptoLogos.WXM, mint: addresses.wxm, decimals: 9 },
    { value: 'ZEX', label: 'ZEX', img: cryptoLogos.ZEX, mint: addresses.zex, decimals: 6 },
    { value: 'BSOL', label: 'BSOL', img: cryptoLogos.BSOL, mint: addresses.bsol, decimals: 9 },
    { value: 'JITOSOL', label: 'JITOSOL', img: cryptoLogos.JITOSOL, mint: addresses.jitosol, decimals: 9 },
    { value: 'MSOL', label: 'MSOL', img: cryptoLogos.MSOL, mint: addresses.msol, decimals: 9 },
    { value: 'CLOUD', label: 'CLOUD', img: cryptoLogos.CLOUD, mint: addresses.cloud, decimals: 9 },
    { value: 'XBG', label: 'XBG', img: cryptoLogos.XBG, mint: addresses.xbg, decimals: 9 },
    { value: 'ALEPH', label: 'ALEPH', img: cryptoLogos.ALEPH, mint: addresses.aleph, decimals: 8 },
    { value: 'VCHF', label: 'VCHF', img: cryptoLogos.VCHF, mint: addresses.vchf, decimals: 9 },
    { value: 'VEUR', label: 'VEUR', img: cryptoLogos.VEUR, mint: addresses.veur, decimals: 9 },
]; 

const App: FC = () => {
    return (
        <ChakraProvider theme={theme}>
            <Web3Provider>
                <Main />
            </Web3Provider>
        </ChakraProvider>
    );
};
export default App;
